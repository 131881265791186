import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Switch, Route} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import App from './components/App';
import Jokes from './components/Jokes';
import MusicMaster from './projects/music-master';
import Header from './components/Header';
import './index.css';

ReactDOM.render(
    <Router history = {createBrowserHistory()}>
      <Switch>
        <Route exact path = '/' render = {() => <Header><App /></Header>} />
        <Route path = '/jokes' render = {() => <Header><Jokes /></Header>} />
        <Route path = '/music-master' render = {() => <Header><MusicMaster /></Header>} />
     </Switch>
    </Router>,document.getElementById('root'));



// class Animal{
// constructor(name, age){
// this.name =name;
// this.age =age;
// }

// speak()
// {
//   console.log('I am', this.name, 'and I am',this.age,'years old.');

// }
// }
//   const anim =new Animal('Simba', 4);
//   anim.speak();

//   class Lion extends Animal{
//     constructor(name, age, furColor, speed)
//     {
//         super(name,age);
//         this.furColor = furColor;
//         this.speed = speed;
//     }

//     roar()
//     {
//        console.log('I have',this.furColor, 'fur color and I run at a speed of',this.speed,'miles an hour.');

//     }
//   }

//   const lion1 = new Lion('Mufasa',20,'golden', 45);
//   lion1.speak();
//   lion1.roar();
// console.log(lion1);

// new Promise ((resolve, reject) => {
    
//     return reject (new Error('No bears'));
//     setTimeout( () => {
//         resolve('Bears, Beats, Beatles');
//     }, 2000);
    

// })
// .then( quote => {
//     console.log(quote);
// }
// )
// .catch(error => console.log('error', error));

