import project1 from '../assets/project4.jpg'
import project2 from '../assets/project5.png'
import project3 from '../assets/project6.jpg'
import project4 from '../assets/project1.png'
import project5 from '../assets/project2.png'

const PROJECTS = [
{   id: 1,
    title: 'Database Administrator Application',
    description: 'A mini project on managment of DB using core Java!',
    link: 'https://github.com/MANTHAN065/Database-Administrator-Application',
    image: project1


},

{   id: 2,
    title: 'ES2ISL',
    description: 'Speech to Sign Language Translator using Python and JS!',
    link: 'https://github.com/MANTHAN065/Speech-To-Sign-Language-Translator',
    image: project2


},
{   id: 3,
    title: 'Music Master',
    description: 'A React app that I built, involving JS and core web concepts!',
    link: 'https://github.com/MANTHAN065/Music-Master',
    image: project3


},

{   id: 4,
    title: 'Stephanie Virtual Assistant',
    description: 'A Python voice assistant app for NLP course project!',
    link: 'https://github.com/MANTHAN065/Stephanie-Virtual-Assistant',
    image: project4

},

{   id: 5,
    title: 'User Login API',
    description: 'A Node JS web app using REST API, OAuth, and Mongo DB!',
    link: 'https://github.com/MANTHAN065/User-Login-API',
    image: project5

}

];

export default PROJECTS;