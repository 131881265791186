import React, { Component } from 'react';
import Artist from './Artist';
import Tracks from './Tracks';
import Search from './Search';

const   API_ADDRESS = 'https://spotify-api-wrapper.appspot.com';
const BASE_SPOTIFY_ADDRESS = 'https://api.spotify.com/v1';
const MINUTES = 1000 * 60;
const REFRESH_RATE = 59 * MINUTES; // refresh every 59 minutes, since the tokens last an hour (3600 seconds)


class App extends Component 
{
    
    state= {artist: null, tracks: []};

    componentDidMount() {

        this.searchArtist('bruno');

    }
   
    searchArtist = artistQuery => {
        
        let globalAccessToken;
        fetch('https://accounts.spotify.com/api/token', { 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Basic ODhhZjc2YWNmYmFlNDY4NWI0NDRjNWYwMGVjZjdkMzc6OWYwMTkyZGZiYzFlNDdkMGEzMDY0ZGY0ZWQ4Y2RiMDE=`, 
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }), 
            body: new URLSearchParams({'grant_type': 'client_credentials'})
        })
        .then(response => response.json())
        .then((user) => {
            globalAccessToken = user.access_token
            fetch(`${BASE_SPOTIFY_ADDRESS}/search?q=${artistQuery}&type=artist`, {  
                headers: new Headers({
                    'Authorization': `Bearer ${globalAccessToken}`, 
                })
            })
            .then(response => response.json())
            .then(json => {
                if (json.artists.total > 0){
                    const artist = json.artists.items[0];
                    this.setState({artist});
        
                    fetch(`${BASE_SPOTIFY_ADDRESS}/artists/${artist.id}/top-tracks?country=US`, {  
                        headers: new Headers({
                            'Authorization': `Bearer ${globalAccessToken}`, 
                        })
                    })
                    .then(response => response.json())
                    .then(json => this.setState({tracks: json.tracks}))
                    .catch(error => alert(error.message));
                }
            })
          })
     
        
    }

    searchTrack = artistQuery => {
        
        let globalAccessToken;
        fetch('https://accounts.spotify.com/api/token', { 
            method: 'post', 
            headers: new Headers({
                'Authorization': `Basic ODhhZjc2YWNmYmFlNDY4NWI0NDRjNWYwMGVjZjdkMzc6OWYwMTkyZGZiYzFlNDdkMGEzMDY0ZGY0ZWQ4Y2RiMDE=`, 
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }), 
            body: new URLSearchParams({'grant_type': 'client_credentials'})
        })
        .then(response => response.json())
        .then((user) => {
            globalAccessToken = user.access_token
            fetch(`${BASE_SPOTIFY_ADDRESS}/search?q=${artistQuery}&type=track`, {  
                headers: new Headers({
                    'Authorization': `Bearer ${globalAccessToken}`, 
                })
            })
            .then(response => response.json())
            .then(json => this.setState({tracks: json.tracks.items, artist: json.tracks.items[0].album}))
            .catch(error => alert(error.message));
            })
          

        
    }

    render() 
    {   
           return (
            <div>
            <h2>Music Master</h2>
            <Search searchArtist = {this.searchArtist} searchTrack = {this.searchTrack} />
            <Artist artist = {this.state.artist} />
            <Tracks tracks = {this.state.tracks} />
            </div>

        )
    }


}   

export default App;