import React, {Component} from 'react';
import music from '../assets/searchbymusic.png';
import artist from '../assets/searchbyartist.png';
import App from './App';

class Search extends Component
{
    state= {artistQuery: '', isArtistView: true}
    updateArtistQuery = event => {
        this.setState({artistQuery: event.target.value});
        }
        
        handleKeyPress = () =>
        {
                if (event.key === 'Enter' && this.state.isArtistView === true){
                this.searchArtist();
        }
                else if (event.key === 'Enter' && this.state.isArtistView === false)
                {
                    this.searchTrack();
                }
     } 

       
        searchArtist = () =>
        {
            this.props.searchArtist(this.state.artistQuery);
        }
        searchTrack = (props) =>
        {
            this.props.searchTrack(this.state.artistQuery);
        }

    render(){

        return (

            <div>  
            { this.state.isArtistView ? <input className = "inp" id = "MyInp"  onChange ={ this.updateArtistQuery} onKeyPress ={this.handleKeyPress} placeholder = 'Search for an Artist'/> : <input className = "inp" id = "MyInp" onChange ={this.updateArtistQuery} onKeyPress ={this.handleKeyPress} placeholder = 'Search for a Track'/>}
            { this.state.isArtistView ? <button className = "butn" onClick= {this.searchArtist}>Search</button> : <button className = "butn" onClick= {this.searchTrack}>Search</button>}
            { this.state.isArtistView ? <button className = "butn2" onClick= {()=>{this.setState({ isArtistView: false });  document.getElementById('MyInp').value = '';}}><img src={music} className = "butn3" title="Click here to search for a track"/></button> : <button className = "butn2" onClick= {()=>{this.setState({ isArtistView: true }); document.getElementById('MyInp').value = '';}}><img src={artist} className = "butn3" title="Click here to search for an artist"/></button>}

            </div>



        )



    }


}

export default Search;