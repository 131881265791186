import React, { Component } from 'react';
import Projects from './Projects';
import SocialProfiles from './SocialProfiles';
import Title from './Title';
import profile from '../assets/profile_1.jpg';



// class RegualarClass { }
// class ComponentClass extends Component { }

// const RegualarClassInstance = new RegualarClass();
// const ComponentClassInstance = new ComponentClass();

// console.log('RegualarClass', RegualarClassInstance);
// console.log('ComponentClass', ComponentClassInstance);



class App extends Component {
    state={displayBio: false};
    // constructor(){
    //     super();
    //     this.state={displayBio: true};
    //     console.log('Component',this);
    //    this.toggleDisplayBio = this.toggleDisplayBio.bind(this);
    // }



    toggleDisplayBio = () => {
        this.setState({displayBio : !this.state.displayBio});
    }

    render() 
    {    
        return (
            <div>
                <img src ={profile} alt='profile' className='profile' />
                <h1>Hello</h1>
                <p>My name is Manthan.</p>
                <Title /> 
                <p>I am actively seeking for job oppurtunities.</p>
                {
                
                this.state.displayBio ? (
                <div>
            <p> I live in Toronto, ON, Canada and code everyday.</p>
            <p>Besides coding, I love music and video games.</p>
            <button className = "butn" onClick={this.toggleDisplayBio}>Show Less</button>
                </div>
            ) : (
                <div>
                <button className="butn" onClick={this.toggleDisplayBio}>Read More</button>
                </div>
            )
                
            }
            <hr />
            <Projects />
            <hr />
            <SocialProfiles />
        </div>
        )
    }


}   

export default App;