import React, {Component} from 'react';
import playIcon from '../assets/play.png';

class Tracks extends Component
{
        state ={ playing: false, audio: null, playingPreviewUrl: null};
        playAudio= previewUrl =>() =>
        {
            const audio = new Audio(previewUrl)

        if (!this.state.playing)
            {       
            audio.play();
            this.setState({playing:true, audio, playingPreviewUrl: previewUrl});  
            }
            else{
                this.state.audio.pause();
                if(this.state.playingPreviewUrl === previewUrl)
                {
                    this.setState({playing: false});

                }
                else
                {
                    audio.play();
                    this.setState({audio, playingPreviewUrl: previewUrl});
                }
                
            }
        }

        trackIcon = track =>
        {
            if(!track.preview_url)
            {   
                return <span className='track-icon'>N/A</span>;

            }
            if (this.state.playing && this.state.playingPreviewUrl === track.preview_url)
            {
                return <span className = 'track-icon'>| |</span>;
            }
        return <img className = 'track-icon' src = {playIcon} />;
        } 

    render(){

    const {tracks} = this.props;
    
    return (

        <div>

            {

                tracks.map(track => {

                    const {id, name, album, preview_url} = track;

                    return (

                        <div key = {id} onClick= {this.playAudio(preview_url)} className='track'>
                            <img src ={album.images[0].url} alt ='track-image' className='track-image' />
                            <p className='track-text'>{name}</p>
                            <p>{this.trackIcon(track)}</p>
                        </div>

                    );

                })


            }
        </div>

    );

    }


}

export default Tracks;