import emailIcon from '../assets/mail.png';
import githubIcon from '../assets/github.png';
import linkedinIcon from '../assets/linkedin.png';
import facebookIcon from '../assets/facebook-green.png';

const SOCIAL_PROFILES= [{
    id: 1,
    link: 'mailto: manthan065@gmail.com',
    image: emailIcon
},
{
    id: 2,
    link: 'https://www.github.com/Manthan065',
    image: githubIcon
},
{
    id: 3,
    link: 'https://www.linkedin.com/in/manthan-khanvilkar-172b691a8',
    image: linkedinIcon
},
{
    id: 4,
    link: 'https://www.facebook.com/manthan.khanvilkar.5',
    image: facebookIcon
},
];

export default SOCIAL_PROFILES;